<template>
  <div class="UserPreviewBody" :style="`--picture:url(${url_encode(picture)});`">
    <div class="UserPreviewContent" :style="`--picture:url(${url_encode(picture)});`">
      <img alt="Image" :src="url_encode(picture)" />
      <div class="UserPreviewOptions">
        <UserConnectBar @close="modal.close()" />
        <div class="UserPreviewDescribe" v-html="decodeURIComponent(user.describe)"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["modal", "user"],
  components: {
    UserConnectBar: () => import("./UserConnectBar.vue"),
  },
  computed: {
    picture: function() {
      return this.user.cover || this.user.picture;
    },
  },
};
</script>

<style lang="scss">
.UserPreview {
  &.modal.default .modal-card {
    background-color: transparent;
    height: auto;
    overflow: hidden;
    overflow-y: hidden;
    z-index: 4;
  }

  .modal-card .modal-card-header {
    background-color: transparent;
    box-shadow: none;
    z-index: 3;
  }

  .UserPreviewOptions {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    padding: $mpadding;
    z-index: 4;
    color: #fff;
    @include textGradient(-90deg);
    display: flex;
    flex-direction: column;
    font-size: 90%;
    .CircleButtonLabel {
      padding: 0 $mpadding/2 $mpadding/2 $mpadding/2;
    }
    .UserConnectBar {
      margin: auto 0 0 0;
    }
  }

  .UserConnectBar {
    position: relative;
    max-width: 120px;
  }

  .modal-card .modal-card-content {
    overflow: hidden;
    position: relative;
    padding: 0;
  }

  .UserPreviewContent {
    width: 100%;
    height: 100%;
    @include fit(--picture, cover);
    img {
      min-height: 480px;
    }
  }

  .modalTitle {
    font-family: $third_font;
    font-size: 120%;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    color: #fff;
  }

  .modal-content {
    @include Glass(rgba(0, 0, 0, 0.3), 0.3);
  }

  .modal-card-header-right {
    .button {
      @include CicleButton(36px);
      margin: $mpadding/2;
    }
  }

  .UserPreviewDescribe {
    position: relative;
  }
}
</style>
